import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import { OptionsList } from './OptionsList';
import { keyboardNavigation } from './keyboardNavigation';
import { METADATA_TITLE, METADATA_URL } from './OptionsList/usePreparedList';
import withPopper from './withPopper';

const Mentions = React.forwardRef((props, ref) => {
  const {
    name,
    defaultValue,
    placeholder,
    onValueChange,
    onInputChange,
    onReference,
    onInputKeyDown,
    onBlur,
    steps,
    readOnly,
    experienceId,
    inputStyle,
    listStyle,
    isOpen,
    autoFocus,
    noTrailingSpace,
    noAutocomplete,
    error,
    optionsListRef,
    listItemRef,
    listItemIndex,
    withGutter,
    onCloseDropdown,
    anchorEl,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [mentionSymbolPosition, setMentionSymbolPosition] = useState(0);
  const [currentReference, setCurrentReference] = useState('');
  const [newReferenceCreated, setNewReferenceCreated] = useState(false);
  const inputRef = ref || useRef(null);
  const currentItemRef = listItemRef || useRef(null);
  const listRef = optionsListRef || useRef(null);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
    setDropdownIsOpen(isOpen);

  }, [isOpen, autoFocus, inputRef.current]);

  useEffect(() => {
    if (!_isNil(listItemIndex)) {
      setCurrentItemIndex(listItemIndex);
    }
  }, [listItemIndex]);

  useEffect(() => {
    if (!_isNil(defaultValue)) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onCloseDropdown && !dropdownIsOpen) {
      onCloseDropdown();
    }
  }, [dropdownIsOpen]);

  const handleInputChange = (e) => {
    const { value, selectionStart } = e.target;

    setInputValue(value);

    if (onInputChange) {
      onInputChange(e.target.value);
    }

    if (value[selectionStart - 1] === '[') {
      setCurrentItemIndex(0);
      setDropdownIsOpen(true);
      setMentionSymbolPosition(selectionStart - 1);
    } else {
      setDropdownIsOpen(false);
    }
  };

  const handleOutsideClick = (e) => {
    setCurrentItemIndex(0);
    if (listRef.current) {
      if (listRef.current.contains(e.target)) {
        return;
      }
    }

    setDropdownIsOpen(false);
  };

  const onClick = () => {
    setDropdownIsOpen(true);
    setCurrentItemIndex(0);
  };

  useEffect(() => {
    if (dropdownIsOpen) {
      window.addEventListener('mousedown', handleOutsideClick, false);
    }

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick, false);
    };
  }, [dropdownIsOpen]);

  const setMergeField = (reference, type, available_types) => {
    setCurrentReference(reference);
    setNewReferenceCreated(true);

    if (onReference) {
      onReference(reference, type, available_types);
    }

    if (readOnly) {
      const value = reference.trim();

      if (onValueChange) {
        onValueChange(value);
      }

      setInputValue(value);
    } else {
      const valueWithReference =
        inputValue.slice(0, mentionSymbolPosition) +
        reference +
        inputValue.slice(mentionSymbolPosition + 1);

      if (onInputChange) {
        onInputChange(valueWithReference);
      }

      setInputValue(valueWithReference);
    }

    setDropdownIsOpen(false);
    setCurrentItemIndex(0);
  };

  const handleLastSelect = (items, type, available_types) => {
    if (/^{{.*}}$/.test(items[2])) {
      // NOTE: when reference was received from API
      setMergeField(items[2], type, available_types);
    } else {
      const referenceKeys = items
        .map((item, index) => {
          const currentStep = steps[index];
          return currentStep.skipFieldRender
            ? ''
            : `${currentStep.referencePrefix}${item}`;
        })
        .filter((item) => item.length > 0);
      const reference = `{{${referenceKeys.join('.')}}}${
        noTrailingSpace ? '' : ' '
      }`;

      setMergeField(reference, type, available_types);
    }
  };

  const buildMetadataReference = (value, id) => {
    switch (value) {
      case METADATA_TITLE:
        return `{{experience_${id}.${value}}} `;
      case METADATA_URL:
        return `{{experience_${value}_${id}}} `;
    }
  };

  const handleExperienceMetadataSelect = (
    selectedExperienceId,
    mergeString,
  ) => {
    let reference = buildMetadataReference(mergeString, selectedExperienceId);
    if (noTrailingSpace) {
      reference = reference.trim();
    }

    setMergeField(reference);
  };

  const setCursorPosition = (referenceLength) => {
    if (inputRef.current && newReferenceCreated) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        mentionSymbolPosition + referenceLength,
        mentionSymbolPosition + referenceLength,
      );
    }
  };

  useEffect(() => {
    setCursorPosition(currentReference.length);
    setNewReferenceCreated(false);
  }, [currentReference, newReferenceCreated]);

  const inputProps = {
    name,
    placeholder,
    error,
    noMargin: !withGutter,
    inputStyle,
    value: inputValue,
    onChange: handleInputChange,
    onKeyDown: (event) => {
      keyboardNavigation(
        event,
        dropdownIsOpen,
        currentItemIndex,
        setCurrentItemIndex,
        currentItemRef,
        listRef,
        onInputKeyDown,
      );
    },
    onBlur,
    ref: inputRef,
    noAutocomplete,
  };

  if (readOnly) {
    inputProps.onClick = onClick;
    inputProps.readOnly = true;
    inputProps.autocomplete = 'off';
    inputProps.inputStyle = { ...inputStyle, cursor: 'pointer', paddingRight: '35px' };
  }

  return (
    <>
      {dropdownIsOpen && (
        <OptionsList
          steps={steps}
          handleLastSelect={handleLastSelect}
          handleExperienceMetadataSelect={handleExperienceMetadataSelect}
          currentItemIndex={currentItemIndex}
          inputWidth={inputRef.current ? inputRef.current.clientWidth : 517}
          listRef={listRef}
          currentItemRef={currentItemRef}
          setCurrentItemIndex={setCurrentItemIndex}
          experienceId={experienceId}
          listStyle={listStyle}
          inputWithGutter={withGutter}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
});

Mentions.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputKeyDown: PropTypes.func,
  onReference: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.bool,
  ]),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      referencePrefix: PropTypes.string,
      loadList: PropTypes.func,
    }),
  ).isRequired,
  inputStyle: PropTypes.object,
  listStyle: PropTypes.object,
  withGutter: PropTypes.bool,
  customInput: PropTypes.bool,
  isOpen: PropTypes.bool,
  noTrailingSpace: PropTypes.bool,
  noAutocomplete: PropTypes.bool,
  readOnly: PropTypes.bool,
  optionsListRef: PropTypes.object,
  listItemIndex: PropTypes.number,
};

export const MentionsInput = withPopper(Mentions);
