import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';

import { MetricItemWrapper } from './style';
import { Input } from '../../../inputs/Input';
import { EditableMentionsInput } from '../../../SlateElements';
import { ExperienceIdContext } from '../../index';
import {
  getExperiencesList,
  getBlocksList,
  getMergeFields,
} from '../../../api';

import {
  Label,
  FormSubsection,
  FormSectionDescription,
} from '../../../globals';
import Checkbox from '../../../checkbox';
import { useTranslation } from '../../../../i18n';

const buildMentionSteps = (t) => [
  {
    type: 'experiences',
    title: t('mentions.expTitle'),
    referencePrefix: 'experience_',
    loadList: getExperiencesList,
  },
  {
    type: 'blocks',
    title: 'Blocks',
    referencePrefix: 'block_',
    loadList: getBlocksList,
  },
  {
    type: 'merge_fields',
    title: 'Block merge fields',
    referencePrefix: '',
    loadList: getMergeFields,
  },
];

function MetricItem({
  label,
  checked,
  checkboxName,
  inputName,
  defaultInputValue,
  inputError,
  updateMetricState,
  stateCheckboxKey,
  stateInputKey,
  placeholder,
}) {
  const [isChecked, setIsChecked] = useState(checked);
  const { t } = useTranslation('translation', {
    keyPrefix: 'MetricSection.MetricItem',
  });
  const mentionSteps = useMemo(() => buildMentionSteps(t), [t]);
  const experienceId = useContext(ExperienceIdContext);

  const handleCheckboxChange = () => {
    setIsChecked(event.target.checked);
    updateMetricState(stateCheckboxKey, event.target.checked);
  };

  const renderContent = () => {
    if (isChecked) {
      return (
        <FormSubsection>
          <Label>Metric description</Label>
          <EditableMentionsInput
            experienceId={experienceId}
            steps={mentionSteps}
            name={inputName}
            initialValue={defaultInputValue}
            placeholder={placeholder}
            error={inputError}
            listStyle={{ left: '12px' }}
            autoFocus
            withGutter
            withHiddenInput
            onValueChange={(value) => {
              updateMetricState(stateInputKey, value);
            }}
          />
        </FormSubsection>
      );
    }
  };

  return (
    <MetricItemWrapper isChecked={isChecked}>
      <Checkbox
        label={label}
        checkedByDefault={isChecked}
        onChange={handleCheckboxChange}
        name={checkboxName}
        value={isChecked}
        data-testid={`MetricSection/${stateInputKey}`}
        withInput
      />
      {renderContent()}
    </MetricItemWrapper>
  );
}

function MetricItemOther({ metricState, updateMetricState, errors }) {
  const [isChecked, setIsChecked] = useState(metricState.otherMetric);
  const { t } = useTranslation('translation', {
    keyPrefix: 'MetricSection.MetricItemOther',
  });
  const mentionSteps = useMemo(() => buildMentionSteps(t), [t]);
  const experienceId = useContext(ExperienceIdContext);

  const handleCheckboxChange = () => {
    setIsChecked(event.target.checked);
    updateMetricState('otherMetric', event.target.checked);
  };

  const renderContent = () => {
    if (isChecked) {
      return (
        <FormSubsection>
          <FormSectionDescription>
            If you have information you want to display that we don't
            automatically track, enter it here and then manually update it.
            Fields are not required.
          </FormSectionDescription>
          <Label>Manual tracking</Label>
          <Input
            name="ask[ask_metric_attributes][other_metric_amount]"
            defaultValue={metricState.otherMetricAmount}
            placeholder="ex: 2"
            error={errors['ask_metric.other_metric_amount']}
            onChange={(e) => {
              updateMetricState('otherMetricAmount', e.target.value);
            }}
          />
          <Label>Label name</Label>
          <EditableMentionsInput
            experienceId={experienceId}
            steps={mentionSteps}
            name="ask[ask_metric_attributes][other_metric_name]"
            initialValue={metricState.otherMetricName}
            placeholder="ex: countries"
            error={errors['ask_metric.other_metric_name']}
            onValueChange={(value) => {
              updateMetricState('otherMetricName', value);
            }}
            listStyle={{ left: '12px' }}
            autoFocus
            withHiddenInput
            withGutter
          />
        </FormSubsection>
      );
    }
  };

  return (
    <MetricItemWrapper isChecked={isChecked}>
      <Checkbox
        label="Other information"
        checkedByDefault={isChecked}
        onChange={handleCheckboxChange}
        name="ask[ask_metric_attributes][other_metric]"
        data-testid="MetricSection/otherMetricName"
        value={isChecked}
        withInput
      />
      {renderContent()}
    </MetricItemWrapper>
  );
}

function MetricSection({ metricState, updateMetricState, errors, isDonation }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'MetricSection',
  });

  return (
    <>
      {isDonation && (
        <MetricItem
          label={t('blockLabel')}
          checked={metricState.amountRaisedMetric}
          checkboxName="ask[ask_metric_attributes][amount_raised_metric]"
          inputName="ask[ask_metric_attributes][amount_raised_metric_name]"
          defaultInputValue={metricState.amountRaisedMetricName}
          inputError={errors['ask_metric.amount_raised_metric_name']}
          updateMetricState={updateMetricState}
          stateCheckboxKey="amountRaisedMetric"
          stateInputKey="amountRaisedMetricName"
          placeholder="ex: raised"
        />
      )}
      <MetricItem
        label={t('experienceLabel')}
        checked={metricState.amountRaisedByExperienceMetric}
        checkboxName="ask[ask_metric_attributes][amount_raised_by_experience_metric]"
        inputName="ask[ask_metric_attributes][amount_raised_by_experience_metric_name]"
        defaultInputValue={metricState.amountRaisedByExperienceMetricName}
        inputError={
          errors['ask_metric.amount_raised_by_experience_metric_name']
        }
        updateMetricState={updateMetricState}
        stateCheckboxKey="amountRaisedByExperienceMetric"
        stateInputKey="amountRaisedByExperienceMetricName"
        placeholder="ex: raised"
      />
      <MetricItem
        label={t('askerLabel')}
        checked={metricState.amountRaisedByAskerMetric}
        checkboxName="ask[ask_metric_attributes][amount_raised_by_asker_metric]"
        inputName="ask[ask_metric_attributes][amount_raised_by_asker_metric_name]"
        defaultInputValue={metricState.amountRaisedByAskerMetricName}
        inputError={errors['ask_metric.amount_raised_by_asker_metric_name']}
        updateMetricState={updateMetricState}
        stateCheckboxKey="amountRaisedByAskerMetric"
        stateInputKey="amountRaisedByAskerMetricName"
        placeholder="ex: total raised"
      />
      <MetricItem
        label={t('variantLabel')}
        checked={metricState.amountRaisedByVariantMetric}
        checkboxName="ask[ask_metric_attributes][amount_raised_by_variant_metric]"
        inputName="ask[ask_metric_attributes][amount_raised_by_variant_metric_name]"
        defaultInputValue={metricState.amountRaisedByVariantMetricName}
        inputError={errors['ask_metric.amount_raised_by_variant_metric_name']}
        updateMetricState={updateMetricState}
        stateCheckboxKey="amountRaisedByVariantMetric"
        stateInputKey="amountRaisedByVariantMetricName"
        placeholder="ex: raised"
      />
      <MetricItem
        label="Number of submissions"
        checked={metricState.submissionsMetric}
        checkboxName="ask[ask_metric_attributes][submissions_metric]"
        inputName="ask[ask_metric_attributes][submissions_metric_name]"
        defaultInputValue={metricState.submissionsMetricName}
        inputError={errors['ask_metric.submissions_metric_name']}
        updateMetricState={updateMetricState}
        stateCheckboxKey="submissionsMetric"
        stateInputKey="submissionsMetricName"
        placeholder="ex: donors"
      />
      <MetricItemOther
        errors={errors}
        metricState={metricState}
        updateMetricState={updateMetricState}
      />
    </>
  );
}

MetricSection.propTypes = {
  metricState: PropTypes.shape({
    amountRaisedByAskerMetric: PropTypes.bool,
    amountRaisedByAskerMetricName: PropTypes.string,
    amountRaisedByExperienceMetric: PropTypes.bool,
    amountRaisedByExperienceMetricName: PropTypes.string,
    amountRaisedMetric: PropTypes.bool,
    amountRaisedMetricName: PropTypes.string,
    otherMetric: PropTypes.bool,
    otherMetricAmount: PropTypes.string,
    otherMetricName: PropTypes.string,
    submissionsMetric: PropTypes.bool,
    submissionsMetricName: PropTypes.string,
  }).isRequired,
  updateMetricState: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isDonation: PropTypes.bool.isRequired,
};

export default MetricSection;
