import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';

export const MUIPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: var(--font-color-secondary);
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 3px;
  }
  .MuiPaginationItem-root:hover {
    color: var(--font-color-primary);
  }
  .MuiPaginationItem-root.Mui-selected {
    color: var(--font-color-primary);
    background-color: var(--pagination-background);
  }
  .MuiPaginationItem-root.Mui-selected:hover {
    background-color: var(--pagination-background);
  }
`;

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
