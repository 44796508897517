import React from 'react';
import MUITooltip, { tooltipClasses } from '@mui/material/Tooltip';

const arrowStyleMapper = {
  bottom: {
    left: '50%',
    transform: 'translate(-50%, 50%) rotate(-135deg)',
    top: '-9px',
  },
  top: {
    left: '50%',
    transform: 'translate(-50%, 50%) rotate(45deg)',
  },
  'top-end': {
    left: '97%',
    transform: 'translate(-50%, 50%) rotate(45deg)',
  },
};

function Tooltip(props) {
  return (
    <MUITooltip
      PopperProps={{
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            position: 'relative',
            maxWidth: props.width ? props.width : '100%',
            backgroundColor: 'var(--background-secondary)',
            color: 'var(--font-color-primary)',
            fontSize: '0.75rem',
            letterSpacing: '0.7px',
            padding: '0.75rem 1rem',
            lineHeight: '1rem',
            borderRadius: '4px',
            border: '1px solid var(--border-color-primary)',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-1px',
              left: arrowStyleMapper[props.placement]?.left,
              top: arrowStyleMapper[props.placement]?.top,
              transform: arrowStyleMapper[props.placement]?.transform,
              width: '0.5rem',
              height: '0.5rem',
              background: 'var(--background-primary)',
              borderBottom: '1px solid var(--border-color-primary)',
              borderRight: '1px solid var(--border-color-primary)',
            },
          },
          [`& .${tooltipClasses.arrow}`]: {
            display: 'none',
          },
        },
      }}
      arrow
      {...props}
    />
  );
}

export default Tooltip;
