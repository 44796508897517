import React from 'react';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';

function StyledRadioButton({ sizeSmall, ...props }) {
  return (
    <Radio
      sx={{
        py: 0.5,
        px: 2,
        borderRadius: '6px',
        color: 'rgba(51, 51, 51, 0.54)',
        '&.Mui-checked, &:hover': {
          color: 'var(--primary-color)',
          bgcolor: 'rgba(255, 66, 0, 0.12)',
        },
        ...(sizeSmall ? {
          px: '9px',
          borderRadius: '10px',
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
        } : {}),
      }}
      disableRipple
      {...props}
    />
  );
}

export default function RadioButtonGroup({
  selectedValue,
  onChange,
  buttons,
  sizeSmall,
}) {
  return (
    <Stack
      sx={{
        height: 50,
        width: 'fit-content',
        p: '4px',
        border: '1px solid var(--border-color-primary)',
        borderRadius: '8px',
        ...(sizeSmall ? {
          height: 38,
          borderRadius: '12px',
          p: '3px',
        } : {}),
      }}
      gap={sizeSmall ? '3px' : 0.5}
      direction="row"
    >
      {buttons.map(({ value, Icon, ariaLabel }) => (
        <StyledRadioButton
          key={value}
          checked={selectedValue === value}
          onChange={() => onChange(value)}
          value={value}
          icon={Icon}
          checkedIcon={Icon}
          inputProps={{ 'aria-label': ariaLabel }}
          sizeSmall={sizeSmall}
        />
      ))}
    </Stack>
  );
}
