import React from 'react';
import PropTypes from 'prop-types';
import { MUIPagination, Wrapper } from './style';

function Pagination({ defaultPage, count, onChange }) {
  return (
    <Wrapper>
      <MUIPagination
        count={count}
        shape="rounded"
        size="large"
        page={defaultPage}
        onChange={onChange}
      />
    </Wrapper>
  );
}

Pagination.propTypes = {
  defaultPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default Pagination;
