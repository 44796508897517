import styled from 'styled-components';
import { Editable } from 'slate-react';
import { Search } from 'react-feather';

export const SearchIcon = styled(Search)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #727278;
  opacity: 0.6;
  cursor: pointer;
`;

export const Input = styled(Editable)`
  display: block;
  background: #fff;
  color: black;
  padding: 0.5rem 15px;
  padding-right: 35px;
  border-radius: 8px;
  border-width: 0px;
  height: 32px;
  margin: 0px;
  box-sizing: content-box;
  line-height: 2;
  cursor: pointer;
`;
